import { Component, input, signal } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    standalone: true,
    imports: [],
    templateUrl: './tooltip.component.html',
    styleUrl: './tooltip.component.scss'
})
export class TooltipComponent {

    text = signal<string>('text');
    }
