<div class="bg-white pb-24 pt-32 px-4">
    <div class="mx-auto max-w-screen-2xl px-6 2xl:px-0">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
            <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                <h2 class="text-3xl font-brand tracking-tight text-black sm:text-5xl">Wij zijn Planmeister</h2>
                <p class="mt-6 text-xl leading-8 text-gray-600">Planmeister werd in 2021 opgericht met de missie om de planning binnen de GWW-sector efficiënter te maken. Inmiddels werken we met een team van negen gepassioneerde professionals dagelijks aan de groei van het Planmeister-netwerk.</p>
                <p class="mt-6 text-xl leading-8 text-gray-600">Ben je nog geen lid? Neem dan vandaag nog contact met ons op en ontdek de voordelen van ons netwerk!</p>
                <div class="mt-10 flex">
                    @if(showCta()) {
                        <button (click)="onCtaClick()" class="rounded-md bg-red-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Account aanmaken <span aria-hidden="true">&rarr;</span></button>
                    } @else {
                        <button (click)="onProfileClick()" class="rounded-md bg-red-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Naar mijn inventaris <span aria-hidden="true">&rarr;</span></button>
                    }
                </div>
            </div>
            <div class="flex flex-col gap-6 sm:gap-8 lg:hidden">
                <img src="/assets/images/homepage/website-12.jpg" alt="" class="aspect-[7/5] w-full rounded-2xl bg-gray-50 object-cover">
                <img src="/assets/images/homepage/website-14.png" alt="" class="aspect-[4/3] w-full rounded-2xl bg-gray-50 object-cover">
                <img src="/assets/images/homepage/website-01.jpg" alt="" class="aspect-[7/5] w-full rounded-2xl bg-gray-50 object-cover">
                <img src="/assets/images/homepage/website-15.png" alt="" class="aspect-[4/3] w-full rounded-2xl bg-gray-50 object-cover">
            </div>
            <div class="hidden lg:flex lg:flex-wrap lg:items-start lg:justify-end lg:gap-6 sm:gap-8 lg:contents">
                <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img src="/assets/images/homepage/website-12.jpg" alt="" class="aspect-[7/5] w-[46rem] max-w-none rounded-2xl bg-gray-50 object-cover">
                </div>
                <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                        <img src="/assets/images/homepage/website-14.png" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
                    </div>
                    <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                        <img src="/assets/images/homepage/website-01.jpg" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
                    </div>
                    <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                        <img src="/assets/images/homepage/website-15.png" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
