<div class="relative isolate overflow-hidden pt-14">
    <div class="mx-auto max-w-screen-2xl px-8 lg:px-0 py-32 lg:pt-32 lg:pb-16 relative z-10 grid grid-cols-6 lg:grid-cols-12">
        <div class="col-span-6">
            <h1 class="text-4xl font-bold tracking-tight text-black sm:text-6xl font-brand">Informeel netwerken voor iedereen in de GWW-sector!</h1>
            <p class="mt-12 text-xl leading-8 text-black">Op 24 april is het weer tijd voor de 10e editie van de Planmeister netwerkavond, en dit keer zijn we te gast bij industrieel veilinghuis Ritchie Bros. in Moerdijk. De locatie in Moerdijk staat bekent om de grootste veilinglocatie van Ritchie Bros. binnen de internationale regio. De ideale locatie voor onze Planmeisters om te netwerken!
            </p>
            <p class="mt-8 text-xl leading-8 text-black">Er is weer een groot scala aan exposanten aanwezig die graag meer vertellen en laten zien over hun bedrijf. Natuurlijk is er ook genoeg gelegenheid om informeel te netwerken, onder het genot van een drankje en een bitterbal.</p>
            <a href="https://www.nowonlinetickets.nl/Shop/EventDetails/7ACA40994AA6869E1B4BA7C9811D16BA" target="_blank" class="mt-12 inline-flex items-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white shadow hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Meld je aan voor de netwerkavond!</a>

        </div>
        <div class="col-span-6 py-20 lg:py-0 px-24">
            <img src="assets/images/events/netwerkavond-moerdijk.png" alt="Flyer van de netwerkavond" class="w-full rounded-sm">
        </div>
    </div>
    <div class="mx-auto max-w-screen-2xl px-6 lg:px-0 pb-24">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div class="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                    <p class="text-xl">Met ongeveer 35 tot 45 exposanten per evenement, krijg je de kans om direct in contact te komen met leveranciers en bedrijven die innovatieve producten en diensten presenteren.</p>
                    <p class="text-xl mt-8">In een ontspannen en informele sfeer worden niet alleen de nieuwste ontwikkelingen en trends besproken, maar krijg je ook de gelegenheid om waardevolle connecties te leggen en samenwerkingen te verkennen. Of je nu een ervaren professional bent of nieuw in de branche, de evenementen bieden iedereen de kans om het eigen netwerk uit te breiden en inzichten te delen.</p>
                </div>
                <div>
                    <div class="flex gap-8">
                        <div class="flex-1 text-left">
                            <p class="text-xl">
                                <b>Waar en Wanneer?</b><br>
                                Ritchie Bros.<br>
                                Mark S Clarkelaan 21<br>
                                Haven M530, Port of Moerdijk<br>
                                4761 RK, Zevenbergen
                            </p>
                        </div>
                        <div class="flex-1 text-left">
                            <p class="text-xl">
                                <b>Datum en Tijd:</b><br>
                                Donderdag 24 april 2025 <br>
                                Aanvang: 18.30 uur
                            </p>
                        </div>
                    </div>
                    <p class="text-xl mt-8"><b>Zorg dat je erbij bent!</b> <br> Meld je eenvoudig aan via het inschrijfformulier op deze pagina. Deelname aan de netwerkavond is gratis, maar het aantal plaatsen is beperkt. Wacht dus niet te lang en verzeker jezelf van een plek op deze waardevolle dag vol kennis, innovatie en netwerkmogelijkheden.</p>
                </div>
            </div>
            <div class="mx-auto mt-24 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                @for (image of blogImages; track image.id) {
                    <div class="aspect-[3/2] overflow-hidden rounded-lg relative group">
                        <img
                            [src]="image.url"
                            [alt]="image.title"
                            class="h-full w-full object-cover transition-opacity"
                        />
                        <div class="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black/60 to-transparent p-4 opacity-100">
                            <h3 class="text-sm font-semibold text-white">{{ image.title }}</h3>
                            <p class="text-xs text-gray-300">{{ image.date }}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    <shell-footer/>
