<div class="overflow-hidden px-4">
    <div class="mx-auto max-w-screen-2xl pb-20 pt-24 sm:pt-60 px-6 2xl:px-0 lg:pt-16">
        <div class="mx-auto max-w-2xl gap-x-20 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div class="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h2 class="xl:text-5xl lg:text-4xl text-3xl font-bold font-brand tracking-tight text-black">Wij staan jou persoonlijk te woord!</h2>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Heb je vragen over onze online tool, netwerkavonden of andere zaken? Wij staan je graag persoonlijk te woord en bieden uitgebreide ondersteuning. Ons backoffice-team staat klaar om al je vragen zorgvuldig te beantwoorden en je verder te helpen met het platform.</p>
                </div>
                <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <div>
                        <h3 class="border-l border-red-600 pl-6 font-semibold text-black">
                            <fa-icon [icon]="faPhone" class="text-xl text-black mb-2"></fa-icon><br>
                            Telefoon
                        </h3>
                        <address class="border-l border-gray-200 pl-6 pt-2 not-italic text-blue-400 sm:text-gray-600">
                            <a href="tel:+31850044938">+31 85 004 49 38</a>
                        </address>
                    </div>
                    <div>
                        <h3 class="border-l border-red-600 pl-6 font-semibold text-black">
                            <fa-icon [icon]="faWhatsapp" class="text-xl text-black mb-2"></fa-icon><br>
                            Whatsapp
                        </h3>
                        <div class="border-l border-gray-200 pl-6 pt-2 not-italic text-blue-400 sm:text-gray-600">
                            <a href="https://wa.me/31621312322">+31 6 21 31 23 22</a>
                        </div>
                    </div>
                    <div>
                        <h3 class="border-l border-red-600 pl-6 font-semibold text-black">
                            <fa-icon [icon]="faEnvelope" class="text-xl text-black mb-2"></fa-icon><br>
                            E-mail
                        </h3>
                        <address class="border-l border-gray-200 pl-6 pt-2 not-italic text-blue-400 sm:text-gray-600">
                            <a href="mailto:info@planmeister.com">info&commat;planmeister.com</a>
                        </address>
                    </div>
                </div>
            </div>
            <div class="mt-16 flex justify-end gap-8 sm:-mt-20 sm:justify-start sm:pl-20 lg:mt-8 lg:pl-0">
                <div class="max-w-sm mx-auto sm:mr-auto flex-none space-y-8 sm:pt-52 lg:pt-36">
                    <div class="relative">
                        <img src="/assets/images/homepage/website-10.jpg" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                        <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
                    </div>
                </div>
                <div class="max-w-sm flex-none space-y-8 pt-32 lg:pt-8 sm:pt-0 hidden sm:block">
                    <div class="relative">
                        <img src="/assets/images/homepage/website-11.jpg" alt="" class="aspect-[1/1] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg">
                        <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
