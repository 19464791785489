<div class="h-28 w-full bg-gray-800"></div>

<!-- PDF viewer for desktop -->
<div class="relative hidden md:block h-screen">
    <iframe [src]="safePdfUrl" class="w-full h-full absolute inset-0"></iframe>
</div>

<!-- Download button for mobile -->
<div class="relative md:hidden h-screen flex items-center justify-center bg-gray-100">
    <button
        (click)="downloadPdf()"
        class="flex items-center gap-2 px-6 py-3 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 transition-colors"
    >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
        </svg>
        Download handleiding
    </button>
</div>

<shell-footer />
