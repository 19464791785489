<div class="bg-white pb-24 pt-32 px-4">
    <div class="mx-auto max-w-screen-2xl px-6 2xl:px-0">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
            <div class="lg:col-end-1 lg:w-full lg:max-w-3xl">
                <p class="text-3xl sm:text-2xl md:text-4xl font-brand tracking-tight text-black">
                    Onze <br class="hidden sm:inline">kernactiviteiten
                </p>
                <p class="mt-6 text-xl leading-8 text-gray-600">Naast onze online tool, Planmeister.com, organiseren we vier keer per jaar informele netwerkbijeenkomsten. Wij zijn namelijk van mening dat een online platform niet werkt zonder dat je elkaar offline leert kennen.</p>
            </div>
        </div>
        <div class="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">

            <div class="flex flex-col justify-end">
                <div class="rounded-3xl bg-white p-8 ring-1 ring-gray-200 lg:mt-8 lg:rounded-r-none xl:p-10">
                    <div>
                        <fa-icon [icon]="faHandshake" class="text-black" size="3x"/>
                        <div class="flex items-center justify-between gap-x-4 mt-4">
                            <h3 id="tier-freelancer" class="text-2xl font-semibold leading-8 text-black">Netwerkavonden</h3>
                        </div>
                        <p class="mt-4 text-md leading-7 text-gray-600">De Planmeister netwerkavonden zijn in 2 jaar tijd uitgegroeid tot toonaangevende bijeenkomsten voor iedereen die werkzaam is in de GWW. Onder het genot van een biertje en een bitterbal kun je netwerken met collega’s uit de GWW.</p>
                        <ul role="list" class="mt-8 space-y-3 text-md leading-7 text-gray-800">
                            <li class="flex gap-x-3">
                                <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                Nieuwe connecties en samenwerking versterken
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                Waardevolle inzichten en ervaringen uitwisselen
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                Op de hoogte blijven van de laatste ontwikkelingen
                            </li>
                        </ul>
                    </div>
                    <a [routerLink]="['/', 'netwerkavonden']" class="mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Lees meer</a>
                </div>
            </div>
            <div class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 lg:z-10 lg:rounded-b-none xl:p-10">
                <div>
                    <fa-icon [icon]="faGlobeEurope" class="text-black" size="3x"/>
                    <div class="flex items-center justify-between gap-x-4 mt-4">
                        <h3 id="tier-startup" class="text-2xl font-semibold leading-8">Planmeister.com</h3>
                    </div>
                    <p class="mt-4 text-md leading-7 text-gray-600">Planmeister.com is een online deelplatform voor de GWW-sector, waarmee je direct inzicht krijgt in de planningen van collega-bedrijven. Het platform biedt realtime informatie over vraag en aanbod binnen de GWW-sector in Nederland, zodat je snel en efficiënt kunt inspelen op de behoeften van de markt.</p>
                    <ul role="list" class="mt-8 space-y-3 text-md leading-7 text-gray-800">
                        <li class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                            </svg>
                            Meer omzet met een kleine investering
                        </li>
                        <li class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                            </svg>
                            Bespaar tijd en geld
                        </li>
                        <li class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-red-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                            </svg>
                            <span>
                            Verduurzaam praktisch en reduceer CO<sup>2</sup> uitstoot
                            </span>
                        </li>
                    </ul>
                </div>
                @if (showCta()) {
                    <button (click)="onCtaClick()" class="mt-8 block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Account aanmaken</button>
                } @else {
                    <button (click)="onProfileClick()" class="mt-8 block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Naar mijn inventaris</button>
                }
            </div>
            <div class="flex flex-col justify-end">
                <div class="rounded-3xl bg-white p-8 ring-1 ring-gray-200 lg:mt-8 lg:rounded-l-none xl:p-10">
                    <div>
                        <fa-icon [icon]="faThumbsUp" class="text-black" size="3x"/>
                        <div class="flex items-center justify-between gap-x-4 mt-4">
                            <h3 id="tier-enterprise" class="text-2xl font-semibold leading-8 text-black">Socials</h3>
                        </div>
                        <p class="mt-4 text-md leading-7 text-gray-600">
                            Profiteer van extra exposure wanneer wij jouw diensten of materieel delen op onze kanalen. Wij hebben een groot bereik en zorgen ervoor dat jouw bedrijf onder de aandacht komt bij een breed publiek.
                        </p>
                        <ul class="mt-8 space-y-3 text-md leading-7 text-gray-600">
                            <li class="flex items-start">
                                <svg class="h-6 w-5 flex-shrink-0 text-red-600 mt-0.5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                <span class="ml-2">Creeër nieuwe opdrachten en connecties</span>
                            </li>
                            <li class="flex items-start">
                                <svg class="h-6 w-5 flex-shrink-0 text-red-600 mt-0.5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                <span class="ml-2">Bereik een breed publiek</span>
                            </li>
                            <li class="flex items-start">
                                <svg class="h-6 w-5 flex-shrink-0 text-red-600 mt-0.5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"/>
                                </svg>
                                <span class="ml-2">Duizenden views per post</span>
                            </li>
                        </ul>
                        <div class="flex w-full gap-x-4 mt-8">
                            <a
                                href="https://www.facebook.com/Planmeister.nl" target="_blank"
                                class="group flex-1 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-red-600 ring-1 ring-inset ring-blue-600 hover:ring-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                <fa-icon [icon]="faFacebookSquare" class="text-blue-600 group-hover:text-blue-900 cursor-pointer transition-colors duration-100" size="lg"></fa-icon>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/planmeisterpuntcom" target="_blank"
                                class="group flex-1 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-red-600 ring-1 ring-inset ring-blue-500 hover:ring-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                <fa-icon [icon]="faLinkedinIn" class="text-blue-500 group-hover:text-blue-800 cursor-pointer transition-colors duration-100" size="lg"></fa-icon>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCKKHaDmfUyLpyXoT24l2wNA" target="_blank"
                                class="group flex-1 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-red-600 ring-1 ring-inset ring-red-500 hover:ring-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                <fa-icon [icon]="faYoutube" class="text-red-500 group-hover:text-red-800 cursor-pointer transition-colors duration-100" size="lg"></fa-icon>
                            </a>
                            <a
                                href="https://www.instagram.com/planmeistergww/" target="_blank"
                                class="group flex-1 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-red-600 ring-1 ring-inset ring-pink-500 hover:ring-pink-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                                <fa-icon [icon]="faInstagram" class="text-pink-500 group-hover:text-pink-800 cursor-pointer transition-colors duration-100" size="lg"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
