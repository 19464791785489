
import { Component, OnInit } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'page-manual',
    standalone: true,
    imports: [
        Footer
    ],
    templateUrl: './manual.component.html'
})
export class ManualComponent implements OnInit {
    safePdfUrl!: SafeResourceUrl;
    private pdfPath = '/assets/pdf/handleiding-planmeister.pdf';

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfPath);
    }

    downloadPdf() {
        const link = document.createElement('a');
        link.href = this.pdfPath;
        link.download = 'handleiding-planmeister.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
