<div class="fixed w-full p-4 z-50">
    <app-nav-bar>
        <router-outlet name="navigation"/>
    </app-nav-bar>
</div>

<router-outlet name="modal-tab"/>

<div [@fadeAnimation]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
</div>

<shell-notifications/>
<p-toast [position]="'bottom-right'" />

<app-modal-container/>

